import http from "../http-common";

class ItemDataService {
  getAll() {
    return http.get("/items");
  }
  getAllNotOnCase(Id) {
    return http.get(`/items/notoncase/${Id}`);
  }

  getAllOnCase(Id) {
    return http.get(`/items/oncase/${Id}`);
  }

  get(Id) {
    return http.get(`/items/${Id}`);
  }

  create(data) {
    return http.post("/items", data);
  }

  update(Id, data) {
    return http.put(`/items/${Id}`, data);
  }

  delete(Id) {
    return http.delete(`/items/${Id}`);
  }

  deleteAll() {
    return http.delete(`/items`);
  }

  findByName(Name) {
    return http.get(`/items?Name=${Name}`);
  }
}

export default new ItemDataService();
