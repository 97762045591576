<template>
  <div class="submit-form">
    <div v-if="!submitted">
      <div class="form-group">
        <label for="name">Navn</label>
        <input
          type="text"
          class="form-control"
          id="name"
          required
          v-model="item.Name"
          name="name"
        />
      </div>

      <div class="form-group">
        <label for="price">Prísur</label>
        <input
          class="form-control"
          id="price"
          required
          v-model="item.Price"
          name="price"
        />
      </div>
      <div class="form-number">
        <label for="price">Vøru nummar</label>
        <input
          class="form-control"
          id="number"
          required
          v-model="item.ItemNumber"
          name="number"
        />
      </div>

      <button @click="saveItem" class="mt-3 btn btn-success">Goym</button>
    </div>

    <div v-else>
      <button class="btn btn-success" @click="newItem">Stovna Nýggjan</button>
    </div>
  </div>
</template>

<script>
import ItemDataService from "../../services/ItemDataService";
import CaseDataService from "../../services/CaseDataService";
export default {
  name: "add-item",
  data() {
    return {
      caseItems: [],
      item: {
        Id: null,
        Name: "",
        Price: "",
        ItemNumber: "",
      },
      submitted: false,
    };
  },
  methods: {
    saveItem() {
      var data = {
        Name: this.item.Name,
        Price: this.item.Price,
        ItemNumber: this.item.ItemNumber,
      };

      ItemDataService.create(data)
        .then((response) => {
          this.item.Id = response.data.Id;
          this.submitted = true;
          this.$router.push({ name: "items-list" });
        })
        .catch((e) => {
          console.log(e);
        });
        
    },

    newItem() {
      this.submitted = false;
      this.item = {};
    },
    retrieveCases() {
      CaseDataService.getAll()
        .then((response) => {
          this.caseItems = response.data;
          console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    this.retrieveCases();
  },
};
</script>

<style>
.submit-form {
  max-width: 300px;
  margin: auto;
}
</style>
